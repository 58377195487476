import React from "react"

import { graphql } from "gatsby"
import TemplateAboutUs from "../templates/templateAboutUs"
import Testimonials from "../components/testimonials"
import InnerPage from "../components/innerPage"
import InnerHeader from "../components/InnerHeader"
import Breadcrumb from "../components/breadcrumb"
import { Helmet } from "react-helmet"

const AboutUs = ({ data }) => {
  const {Title, Description, Image} = data.strapiAboutUs;

  return (
    <InnerPage>
      <Helmet>
        <title>Despre noi</title>
        <meta name="Description" content={Description}/>
      </Helmet>
      <InnerHeader>
        <Breadcrumb label="Despre noi"  />
      </InnerHeader>
      <TemplateAboutUs title={Title.Title} subtitle={Title.Subtitle} description={Description} image={Image.publicURL} />
      <Testimonials />
    </InnerPage>
  )
}

export default AboutUs

export const pageQuery = graphql`
  query AboutQuery {
    strapiAboutUs {
      Description
      Title {
        Title
        Subtitle
      }
      Image {
        publicURL
      }
    }
  }
 `